<template>
    <div class="client-profile">
        <fatca
                card-title="Анкета физического лица"
                :page-title="pageTitle"
                :info-text="infoText"
                :fatca-cards="cards"
                :has-draft="hasDraft"
                :actual="actual"
                @download="download"
                @download-scan="downloadScan"
                @fillOut="$router.push('/cabinet/documents/forms/pod-ft')"
        />
    </div>
</template>

<script>
    import Fatca from "../../../components/pages/cabinet/documents/FATCA";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DocumentsPod",
        components: {Fatca},
        watch: {
            docChanged() {
                if(this.changedDocAlias === "qst-pod_ft")
                    this.GET_DOCUMENTS("qst-pod_ft")
            }
        },
        computed: {
            ...mapGetters("DocumentsModule", ["getDOCUMENTS", "getDRAFT", "getACTUAL_DOCUMENT"]),
            ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias"]),
            actual() {
                return this.getACTUAL_DOCUMENT && this.getACTUAL_DOCUMENT.length > 0 ? this.getACTUAL_DOCUMENT[0] : null
            },
            hasDraft() {
                return !!this.getDRAFT
            },
            cards() {
                return this.getDOCUMENTS ? this.getDOCUMENTS.map(el => {
                    let element = {...el}
                    element.title = "Анкета физического лица"
                    element.downloadLink = true
                    return element
                }) : null
            }
        },
        data() {
            return {
                pageTitle: "Анкеты физического лица",
                infoText: "В случае ошибочно введенных и подписанных данных, Вы можете перезаполнить/подписать и отправить анкету снова и последняя редакция будет считаться актуальной",
                // infoBlock: "Это какой-то информационный текст для всех страниц с карточками. Лица могут быть признаны квалифицированными инвесторами, если они отвечают требованиям, установленным Федеральным законом \"О рынке ценных бумаг\" и принятыми в соответствии с ним нормативными актами Банка России."
            }
        },
        methods: {
            ...mapActions("DocumentsModule", [
                "GET_DOCUMENTS",
                "DOWNLOAD_DOCUMENT"
            ]),
            ...mapActions("regModule", [
                "DOWNLOAD_FILE"
            ]),
            download(link) {
                this.DOWNLOAD_DOCUMENT({id: link, name: "Анкета ПОД/ФТ.pdf"})
            },
            downloadScan(file) {
                this.DOWNLOAD_FILE(file)
            },
        },
        mounted() {
            this.GET_DOCUMENTS("qst-pod_ft")
        }
    }
</script>

<style scoped lang="scss">
</style>